import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';

import { useErrorToaster } from '../../utils/errorToaster';
import config from '../../config';
import {
  DAMAGE_REPORT_STEP_SUBMIT,
  UPDATE_STEP
} from '../../store/actions/damage_report';
import Step from '../../components/damage_report/Step';
import FileUploadButton from '../../components/shared/FileUploadButton';
import defaultImage from '../../assets/images/damage_report/front@2x.png';
import { uploadPicture } from '../../store/actions/damage_report';
import api from '../../utils/api';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    bottom: 0,
    top: 'initial',
    backgroundColor: '#fafafa'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px',
    marginBottom: '10px'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  WhiteSpace: {
    height: '200px'
  }
}));

function AdditionalPage({ history }) {
  const { t } = useTranslation(['damage_report']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultImage);
  const [currentUploaders, setCurrentUploaders] = useState([null]);
  const damage_report = useSelector(state => state.damage_report);

  useEffect(() => {
    let lastElement = currentUploaders.slice(-1)[0];
    if (lastElement !== null) {
      setCurrentUploaders([...currentUploaders, null]);
      dispatch({
        type: UPDATE_STEP,
        step: 'ADDITIONAL',
        value: [...damage_report.ADDITIONAL, null]
      });
    }
  }, [currentUploaders]);

  const handleNext = () => {
    dispatch({
      type: DAMAGE_REPORT_STEP_SUBMIT
    });
  };

  const onFileDeleted = index => {
    setUploaded(false);
    setCurrentImage(defaultImage);

    const list = currentUploaders.map((item, i) => {
      if (i === index) {
        return null;
      } else {
        return item;
      }
    });

    const state = damage_report.ADDITIONAL.map((item, i) => {
      if (i === index) {
        return null;
      } else {
        return item;
      }
    });

    setCurrentUploaders(list);
    dispatch({
      type: UPDATE_STEP,
      step: 'ADDITIONAL',
      value: state
    });
  };

  const handleDocumentUpload = async (
    type,
    index,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    try {
      const responseLink = await api.picture.getPictureLinkUrl();
      const response = await api.picture.uploadPictureS3(
        responseLink.upload_url,
        file,
        {},
        {
          onUploadProgress: event => {
            const percent = Math.round((event.loaded * 100) / event.total);
            setProgress(percent);
          }
        }
      );

      const list = currentUploaders.map((item, i) => {
        if (i === index) {
          return file;
        } else {
          return item;
        }
      });

      const state = damage_report.ADDITIONAL.map((item, i) => {
        if (i === index) {
          return {
            type: 'ADDITIONAL',
            key: responseLink.key,
            comment: null
          };
        } else {
          return item;
        }
      });

      dispatch({
        type: UPDATE_STEP,
        step: 'ADDITIONAL',
        value: state
      });

      setCurrentUploaders(list);
      setCompleted();
      setUploaded(true);
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const renderFileUploader = (currentImage, index) => {
    return (
      <FileUploadButton
        key={index}
        className="upload-button"
        image={
          <img
            src={
              currentImage === null
                ? defaultImage
                : URL.createObjectURL(currentImage)
            }
          />
        }
        onFileDeleted={() => onFileDeleted(index)}
        onFileSelected={(file, callbacks) =>
          handleDocumentUpload('ADDITIONAL', index, file, callbacks)
        }
        accept={'image/*, application/pdf'}
      >
        <span>{currentImage === null ? 'prendre une photo' : null}</span>
      </FileUploadButton>
    );
  };

  return (
    <div>
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          justify="center"
          variant="h5"
          color="textSecondary"
          paragraph
        >
          {t('damage_report:additional-step-title')}
        </Typography>

        <Typography
          className={classes.textCenter}
          justify="center"
          variant="h6"
          color="textSecondary"
          paragraph
        >
          {t('damage_report:additional-step-description')}
        </Typography>

        {currentUploaders.map((currentImage, index) =>
          renderFileUploader(currentImage, index)
        )}

        <span className={classes.WhiteSpace}></span>
      </Container>
      <AppBar className={classes.AppBarBottom} position="fixed">
        <Container maxWidth="md">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                onClick={handleNext}
                className={classes.Button}
                variant="contained"
                color="primary"
                size="large"
              >
                {t('damage_report:step-button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </div>
  );
}

export default withRouter(AdditionalPage);
