import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';
import ErrorBoundary from './ErrorBoundary';
import Front from './Front';

const App = () => {
  const store = configureStore();

  const loadFrontoffice = (
    <ErrorBoundary>
      <Front />
    </ErrorBoundary>
  );

  return <Provider store={store}>{loadFrontoffice}</Provider>;
};

export default App;
