import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { DAMAGE_REPORT_STEP_BACK } from '../../store/actions/damage_report';
import right from '../../assets/images/damage_report/right@2x.png';
import Step from '../../components/damage_report/Step';

function RightPage({ history }) {
  const { t } = useTranslation(['damage_report']);

  return (
    <div className="">
      <Step
        title={t('damage_report:right-step-title')}
        description={t('damage_report:right-step-description')}
        currentStep={'RIGHT'}
        nextStep={DAMAGE_REPORT_STEP_BACK}
        image={right}
      />
    </div>
  );
}

export default withRouter(RightPage);
