import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import MultiStepForm from '../components/layout/MultiStepForm';
import LeftPage from './damage_report/LeftPage';
import FrontPage from './damage_report/FrontPage';
import RightPage from './damage_report/RightPage';
import BackPage from './damage_report/BackPage';
import OdometerPage from './damage_report/OdometerPage';
import AdditionalPage from './damage_report/AdditionalPage';
import SubmitPage from './damage_report/SubmitPage';
import ToolAppBar from '../components/nav/ToolAppBar';
import CompleteComponent from '../components/damage_report/CompleteComponent';

function DamageReportPage({ history }) {
  const { t } = useTranslation(['account']);
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.damage_report.currentStep);
  const isDamageReportComplete = useSelector(
    state => state.damage_report.isDamageReportComplete
  );
  const damage_report = useSelector(state => state.damage_report);

  console.log('[damage_report] =>', damage_report);

  const steps = {
    LEFT: 'LEFT',
    FRONT: 'FRONT',
    RIGHT: 'RIGHT',
    BACK: 'BACK',
    ODOMETER: 'ODOMETER',
    ADDITIONAL: 'ADDITIONAL',
    SUBMIT: 'SUBMIT'
  };

  const components = {
    LEFT: LeftPage,
    FRONT: FrontPage,
    RIGHT: RightPage,
    BACK: BackPage,
    ODOMETER: OdometerPage,
    ADDITIONAL: AdditionalPage,
    SUBMIT: SubmitPage
  };

  const formComponent = (
    <MultiStepForm
      steps={steps}
      activeStep={currentStep}
      components={components}
    />
  );

  return (
    <div>
      <ToolAppBar />
      {isDamageReportComplete ? <CompleteComponent /> : formComponent}
    </div>
  );
}

export default withRouter(DamageReportPage);
