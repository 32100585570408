import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

import AnimatedCheckmark from '../shared/AnimatedCheckmark';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    backgroundColor: 'transparent',
    bottom: 0,
    top: 'initial',
    backgroundColor: 'white'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px'
  }
}));

const CompleteComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['damage_report']);
  const damage_report = useSelector(state => state.damage_report);
  const [isStart, setIsStart] = useState(false);
  const [isEnd, setisEnd] = useState(false);

  useEffect(() => {
    const start = _.find(damage_report.reservation.damage_reports, {
      type: 'start'
    });
    const end = _.find(damage_report.reservation.damage_reports, {
      type: 'end'
    });

    setIsStart(start ? false : true);
    setisEnd(end ? false : true);
  }, []);

  return (
    <div>
      <div className="complete-message">
        <AnimatedCheckmark />
      </div>
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          variant="h4"
          color="primary"
          justify="center"
          paragraph
        >
          {t('damage_report:end-title')}{' '}
          {damage_report.reservation.reservation_id}
        </Typography>
        <Typography
          className={classes.textCenter}
          variant="h6"
          color="primary"
          justify="center"
          paragraph
        >
          {isStart ? (
            <div>
              {t('damage_report:end-content-1')}
              <br />
              {t('damage_report:end-content-2')}
            </div>
          ) : (
            <div>{t('damage_report:end-content-end-1')}</div>
          )}
          <br />
        </Typography>
      </Container>
    </div>
  );
};

export default CompleteComponent;
