import axios from 'axios';
import config from '../../config';
import httpClient from '../httpClient';
import _ from 'lodash';

const API_URL = _.trimEnd(config.api.url, '/');

export default {
  findReservation(vehicle_plate, reservation_start_date) {
    return httpClient(API_URL).post('/reservations/search', {
      reservation_start_date: reservation_start_date,
      vehicle_plate: vehicle_plate
    });
  },
  submitDamageReport(uuid, data) {
    return httpClient(API_URL).post(`reservations/${uuid}/reports`, data);
  }
};
