import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

function LineStepper({ steps, currentIndex }) {
  const elements = [];

  for (let i = 0; i < steps; i += 1) {
    elements.push(
      <Button
        key={`step-${i}`}
        className={
          i === currentIndex
            ? 'primary'
            : i < currentIndex
            ? 'secondary'
            : 'outlined'
        }
        fullWidth
        disabled={true}
      >
        {i + 1}
      </Button>
    );
  }

  return (
    <Container className="stepper" maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center">
        <ButtonGroup
          className="button-group"
          variant="contained"
          aria-label="contained primary button group"
        >
          {elements}
        </ButtonGroup>
      </Box>
    </Container>
  );
}

LineStepper.propTypes = {
  steps: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired
};

export default LineStepper;
