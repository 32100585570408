const cargo = {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '4px'
      },
      sizeLarge: {
        '& $label': {
          padding: '8px'
        }
      }
    }
  },
  tenant: {
    name: 'Openfleet',
    assets: {
      favicon: 'favicon',
      logo: 'tenantLogo'
    }
  },
  typography: {
    fontFamily: [
      'SofiaPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#102d5e',
      main: '#102d5e',
      dark: '#102d5e'
    },
    secondary: {
      main: '#2a74ed'
    },
    background: {
      default: '#ffffff'
    }
  }
};

export default cargo;
