import React, { createRef, useState } from 'react';
import classNames from 'classnames';
import UploadProgress from './UploadProgress';
import checkIcon from '../../assets/images/check.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function FileUploadButton({
  children,
  disabled,
  image,
  className,
  onFileSelected,
  onFileDeleted,
  accept = '*'
}) {
  const fileInputRef = createRef();
  const [state, setState] = useState({
    isUploading: false,
    isCompleted: false,
    progress: 0
  });

  const openFileDialog = () => {
    if (!state.isCompleted && !state.isUploading) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelection = e => {
    const files = e.target.files;

    if (files[0] && typeof onFileSelected === 'function') {
      onFileSelected(files[0], {
        setStarted,
        setProgress,
        setCompleted,
        resetState
      });
    }
  };

  const handleFileClick = e => {
    // security feature preventing JavaScript from knowing the file's absolute path
    e.target.value = '';
  };

  const setProgress = value => {
    setState({
      ...state,
      isUploading: true,
      progress: value
    });
  };

  const resetState = () => {
    setState({
      isUploading: false,
      isCompleted: false,
      progress: 0
    });
  };

  const setStarted = () => {
    setState({
      ...state,
      progress: 0,
      isUploading: true
    });
  };

  const setCompleted = () => {
    setState({
      ...state,
      progress: 100,
      isUploading: false,
      isCompleted: true
    });
  };

  const handleDelete = () => {
    if (typeof onFileDeleted === 'function') {
      onFileDeleted();
    }
    setState({
      ...state,
      isCompleted: false
    });
  };

  const completedIcon = (
    <div onClick={handleDelete} className="delete-icon">
      <HighlightOffIcon className="icon" />
    </div>
  );

  return (
    <div
      className={classNames({
        [className]: !!className,
        'file-upload-button': true,
        uploading: state.isUploading,
        'upload-success': state.isCompleted
      })}
      onClick={openFileDialog}
    >
      {state.isCompleted ? completedIcon : null}
      {image && !state.isUploading ? (
        <div className="image">{image}</div>
      ) : null}
      {!state.isUploading ? <div className="label">{children}</div> : null}
      {state.isUploading ? <CircularProgress /> : null}

      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        disabled={state.isUploading}
        accept={accept}
        onClick={handleFileClick}
        onChange={handleFileSelection}
      />
    </div>
  );
}

export default FileUploadButton;
