import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ToolAppBar from '../components/nav/ToolAppBar';
import config from '../config';
import { DAMAGE_REPORT_TYPE } from '../store/actions/damage_report';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    backgroundColor: 'transparent',
    bottom: 0,
    top: 'initial',
    backgroundColor: 'white'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textInfo: {
    textAlign: 'center',
    marginTop: '10px'
  },
  textCenter: {
    textAlign: 'center'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px'
  }
}));

function DamageReportIntroPage({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const damage_report = useSelector(state => state.damage_report);
  const reservation_id = damage_report.reservation.reservation_id;
  const { t } = useTranslation(['damage_report']);
  const [isStart, setIsStart] = useState(false);
  const [isEnd, setisEnd] = useState(false);
  const [is24hour, setIs24hour] = useState(false);
  const utcCutoff = moment.utc(damage_report.reservation.reservation_start_at);
  const dateToFr = utcCutoff.clone().tz('Europe/Paris');

  useEffect(() => {
    const start = _.find(damage_report.reservation.damage_reports, {
      type: 'start'
    });
    const end = _.find(damage_report.reservation.damage_reports, {
      type: 'end'
    });
    var ONE_DAY = 24 * 60 * 60 * 1000;
    let is24hour = dateToFr.tz('Europe/Paris').toDate() - new Date() < ONE_DAY;
    
    setIs24hour(is24hour);
    setIsStart(start ? false : true);
    setisEnd(end ? false : true);
  }, []);

  const handleSubmit = type => {
    dispatch({
      type: DAMAGE_REPORT_TYPE,
      damageReportType: type
    });
    if (type == 'start')
      history.push(`${config.auth.rootUrl}etat-des-lieux/debut`);
    else if (type == 'end')
      history.push(`${config.auth.rootUrl}etat-des-lieux/fin`);
  };

  const infoDriver = () => {
    if (
      !damage_report.reservation.driver_email &&
      !damage_report.reservation.driver_firstname &&
      !damage_report.reservation.driver_lastname
    )
      return;
    return (
      <Typography
        className={classes.textCenter}
        color="primary"
        justify="center"
        paragraph
      >
        Conducteur : {damage_report.reservation.driver_firstname}{' '}
        {damage_report.reservation.driver_lastname}{' '}
      </Typography>
    );
  };

  const infoCustomer = () => {
    if (
      !damage_report.reservation.customer_email &&
      !damage_report.reservation.customer_firstname &&
      !damage_report.reservation.customer_lastname
    )
      return;
    return (
      <Typography
        className={classes.textCenter}
        color="primary"
        justify="center"
        paragraph
      >
        Client : {damage_report.reservation.customer_firstname}{' '}
        {damage_report.reservation.customer_lastname}{' '}
      </Typography>
    );
  };

  const infoVehicle = () => {
    if (
      !damage_report.reservation.vehicle_plate &&
      !damage_report.reservation.vehicle_modele &&
      !damage_report.reservation.vehicle_make
    )
      return;
    return (
      <Typography
        className={classes.textCenter}
        color="primary"
        justify="center"
        paragraph
      >
        {t('damage_report:intro-vehicle')} :{' '}
        {damage_report.reservation.vehicle_plate}{' '}
        {damage_report.reservation.vehicle_make}{' '}
        {damage_report.reservation.vehicle_modele}{' '}
      </Typography>
    );
  };

  const infoReservation = () => {
    return (
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          variant="h4"
          color="primary"
          justify="center"
          paragraph
        >
          {t('damage_report:intro-title')}{' '}
          {damage_report.reservation.reservation_id}
        </Typography>
        {infoCustomer()}
        {infoDriver()}
        {infoVehicle()}
        <Typography
          className={classes.textInfo}
          color="primary"
          justify="center"
          paragraph
        >
          {t('damage_report:intro-content-1')}
          <br />
          {t('damage_report:intro-content-2')}
          <br />
          {t('damage_report:intro-content-3')}
        </Typography>
      </Container>
    );
  };

  return (
    <div className="">
      <ToolAppBar />
      {infoReservation()}
      <AppBar className={classes.AppBarBottom} position="fixed">
        <Container color="primary" maxWidth="md">
          <Grid container spacing={2} justify="center">
            <Grid item>
              {is24hour && (isStart || isEnd) ? (
                <Button
                  onClick={() =>
                    isStart ? handleSubmit('start') : handleSubmit('end')
                  }
                  className={classes.Button}
                  variant="contained"
                  disabled={false}
                  size="large"
                  color="primary"
                >
                  {isStart
                    ? t('damage_report:intro-button-start')
                    : t('damage_report:intro-button-end')}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </div>
  );
}

export default withRouter(DamageReportIntroPage);
