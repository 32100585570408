import api from '../../utils/api';
export const GET_GEOLOC = 'GET_GEOLOC';

export function userAskGeoloc() {
  return async function(dispatch) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          console.log('position', position);
          dispatch({
            type: GET_GEOLOC,
            geolocationCoordinates: position.coords
          });
        },
        function errorCallback(error) {
          console.log('error', error);
        },
        {
          timeout: 5000
        }
      );
    }
  };
}
