import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { userAskGeoloc } from '../store/actions/user';
import config from '../config';
import ToolAppBar from '../components/nav/ToolAppBar';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    bottom: 0,
    top: 'initial',
    backgroundColor: 'white'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  buttonGrp: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  WhiteSpace: {
    height: '200px'
  }
}));

function GeolocPage({ history }) {
  const { t } = useTranslation(['account']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const geolocationCoordinates = useSelector(
    state => state.damage_report.geolocationCoordinates
  );
  const reservation = useSelector(state => state.damage_report.reservation);

  useEffect(() => {
    if (geolocationCoordinates.longitude && geolocationCoordinates.latitude) {
      history.push(`${config.auth.rootUrl}etat-des-lieux/intro`);
    }
  }, [geolocationCoordinates]);

  const handleUserAskGeoloc = () => {
    dispatch(userAskGeoloc());
  };

  return (
    <div className="">
      <ToolAppBar />
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          variant="h4"
          color="primary"
          justify="center"
          paragraph
        >
          {t('damage_report:geoloc-title')}
        </Typography>
        <Typography
          className={classes.textCenter}
          color="primary"
          justify="center"
          paragraph
        >
          {t('damage_report:geoloc-content-1')}
          <br />
          {t('damage_report:geoloc-content-2')}
          <br />
        </Typography>
        <span className={classes.WhiteSpace}></span>
      </Container>
      <AppBar className={classes.AppBarBottom} position="fixed">
        <Container color="primary" maxWidth="md">
          <Grid className={classes.buttonGrp} container justify="center">
            <Grid item xs={12}>
              <Button
                onClick={handleUserAskGeoloc}
                className={classes.Button}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('damage_report:geoloc-button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </div>
  );
}

export default withRouter(GeolocPage);
