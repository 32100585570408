import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { DAMAGE_REPORT_STEP_ADDITIONAL } from '../../store/actions/damage_report';
import back from '../../assets/images/damage_report/back@2x.png';
import Step from '../../components/damage_report/Step';

function OdometerPage({ history }) {
  const { t } = useTranslation(['damage_report']);

  return (
    <div className="">
      <Step
        title={t('damage_report:odometer-step-title')}
        description={t('damage_report:odometer-step-description')}
        currentStep={'ODOMETER'}
        nextStep={DAMAGE_REPORT_STEP_ADDITIONAL}
        image={back}
      />
    </div>
  );
}

export default withRouter(OdometerPage);
