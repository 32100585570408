import React from 'react';
import PropTypes from 'prop-types';
import LineStepper from '../damage_report/LineStepper';

function MultiStepForm({ steps, activeStep, components }) {
  const stepCount = Object.keys(steps).length || 0;
  const currentIndex = Object.keys(steps).indexOf(activeStep);

  const lineStepper = (
    <LineStepper steps={stepCount} currentIndex={currentIndex} />
  );

  const $component =
    components && components[activeStep] ? components[activeStep] : null;

  return (
    <div>
      {lineStepper}
      {$component ? <$component /> : null /* eslint-disable-line */}
    </div>
  );
}

MultiStepForm.propTypes = {
  steps: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired
};

export default MultiStepForm;
