import React, { useEffect, useState } from 'react';

import AppRoutes from './AppRoutes';
import CenteredLoader from './components/shared/CenteredLoader';
import { useLanguageLoader } from './hooks/languageLoader';

const MainComponent = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const useLanguage = useLanguageLoader();

  const loadData = async () => {
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return !isLoading ? (
    <main>
      <AppRoutes />
    </main>
  ) : (
    <CenteredLoader />
  );
};
export default MainComponent;
