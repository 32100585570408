import React from 'react';

import Routes from './Routes';

const AppRoutes = props => {
  return (
    <div>
      <Routes {...props} />
    </div>
  );
};

export default AppRoutes;
