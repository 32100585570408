import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useErrorToaster } from '../../utils/errorToaster';
import api from '../../utils/api';
import config from '../../config';
import { uploadPicture } from '../../store/actions/damage_report';
import FileUploadButton from '../../components/shared/FileUploadButton';

import { UPDATE_STEP } from '../../store/actions/damage_report';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    bottom: 0,
    top: 'initial',
    backgroundColor: 'white'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  WhiteSpace: {
    height: '200px'
  }
}));

const Step = ({ title, description, image, currentStep, nextStep }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['damage_report']);
  const displayError = useErrorToaster();
  const [isUploaded, setUploaded] = useState(false);
  const [currentImage, setCurrentImage] = useState(image);

  const handleNext = async () => {
    dispatch({
      type: nextStep
    });
  };

  const onFileDeleted = async () => {
    setUploaded(false);
    setCurrentImage(image);
    dispatch({
      type: UPDATE_STEP,
      step: currentStep,
      value: null
    });
  };

  const handleDocumentUpload = async (
    type,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    try {
      const response = await dispatch(uploadPicture(currentStep, file));
      setCurrentImage(URL.createObjectURL(file));

      setCompleted();
      setUploaded(true);
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  return (
    <div>
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          justify="center"
          variant="h4"
          color="primary"
          paragraph
        >
          {title}
        </Typography>

        <Typography
          className={classes.textCenter}
          justify="center"
          color="primary"
          paragraph
        >
          {description}
        </Typography>

        <FileUploadButton
          className="upload-button"
          image={<img src={currentImage} />}
          onFileDeleted={onFileDeleted}
          onFileSelected={(file, callbacks) =>
            handleDocumentUpload(currentStep, file, callbacks)
          }
          accept={'image/*'}
        >
          <span>
            {!isUploaded ? t('damage_report:take-picture-text') : null}
          </span>
        </FileUploadButton>
        <span className={classes.WhiteSpace}></span>
      </Container>
      <AppBar className={classes.AppBarBottom} position="fixed">
        <Container color="primary" maxWidth="md">
          <Grid container justify="center">
            <Grid item>
              <Button
                onClick={handleNext}
                className={classes.Button}
                variant="contained"
                size="large"
                disabled={!isUploaded}
                color="primary"
              >
                {t('damage_report:step-button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </div>
  );
};

export default Step;
