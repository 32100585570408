import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import config from './config';
import DamageReportPage from './views/DamageReportPage';
import DamageReportIntroPage from './views/DamageReportIntroPage';
import LoginPage from './views/LoginPage';
import GeolocPage from './views/GeolocPage';
import PrivateRoute from './components/routing/PrivateRoute';

const Routes = ({ tenant }) => {
  return (
    <div>
      <Route exact path={`${config.auth.rootUrl}`}>
        {true ? <LoginPage /> : <LoginPage />}
      </Route>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/intro`}
        component={DamageReportIntroPage}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/debut`}
        component={DamageReportPage}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/fin`}
        component={DamageReportPage}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/geoloc`}
        component={GeolocPage}
      ></PrivateRoute>
    </div>
  );
};

export default Routes;
