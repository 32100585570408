import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/images/logo-retina.png';

const ToolAppBar = () => {
  return (
    <AppBar className="app-bar" position="relative">
      <Container maxWidth="md">
        <Toolbar>
          <img className="logo" src={logo} alt={'logo'}></img>
          <Typography className="title" variant="h5" color="inherit" noWrap>
            État des lieux
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ToolAppBar;
