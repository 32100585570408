import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { useSnackbar } from 'notistack';

import config from '../../config';
import { SUBMITED_DAMAGE_REPORT } from '../../store/actions/damage_report';
import { submitDamageReport } from '../../store/actions/damage_report';

const useStyles = makeStyles(theme => ({
  AppBarBottom: {
    backgroundColor: 'transparent',
    bottom: 0,
    top: 'initial',
    backgroundColor: 'white'
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  Button: {
    marginTop: '8px',
    marginBottom: '8px'
  }
}));

function SubmitPage({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['damage_report']);
  const damage_report = useSelector(state => state.damage_report);
  const uuid = damage_report.reservation.uuid;
  const { enqueueSnackbar } = useSnackbar();

  const submitEndDamageReport = async () => {
    try {
      setIsLoading(true);
      let pictures = [];
      pictures.push(damage_report.LEFT);
      pictures.push(damage_report.FRONT);
      pictures.push(damage_report.RIGHT);
      pictures.push(damage_report.BACK);
      pictures.push(damage_report.ODOMETER);
      {
        damage_report.ADDITIONAL.map((additionalPicture, index) =>
          additionalPicture ? pictures.push(additionalPicture) : null
        );
      }

      const data = {
        type: damage_report.damageReportType,
        completed_by: damage_report.currentUserEmail,
        latitude: damage_report.geolocationCoordinates.latitude,
        longitude: damage_report.geolocationCoordinates.longitude,
        pictures: pictures
      };

      const results = await dispatch(submitDamageReport(uuid, data));
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('error', { variant: 'error' });
      setIsLoading(false);
    }
  };

  const infoDriver = () => {
    if (
      !damage_report.reservation.driver_email &&
      !damage_report.reservation.driver_firstname &&
      !damage_report.reservation.driver_lastname
    )
      return;
    return (
      <Typography
        className={classes.textCenter}
        color="primary"
        justify="center"
        paragraph
      >
        Conducteur : {damage_report.reservation.driver_firstname}{' '}
        {damage_report.reservation.driver_lastname}{' '}
      </Typography>
    );
  };

  const infoCustomer = () => {
    if (
      !damage_report.reservation.customer_email &&
      !damage_report.reservation.customer_firstname &&
      !damage_report.reservation.customer_lastname
    )
      return;
    return (
      <Typography
        className={classes.textCenter}
        color="primary"
        justify="center"
        paragraph
      >
        Client : {damage_report.reservation.customer_firstname}{' '}
        {damage_report.reservation.customer_lastname}{' '}
      </Typography>
    );
  };

  return (
    <div className="">
      <Container className={classes.paper} maxWidth="md">
        <Typography
          className={classes.textCenter}
          color="primary"
          justify="center"
          paragraph
        >
          Réservation : {damage_report.reservation.reservation_id}
        </Typography>
        <Typography
          className={classes.textCenter}
          color="primary"
          justify="center"
          paragraph
        >
          Contrat : {damage_report.reservation.contrat_id}
        </Typography>
        <Typography
          className={classes.textCenter}
          color="primary"
          justify="center"
          paragraph
        >
          {infoCustomer()}
          {infoDriver()}
          {t('damage_report:submit-vehicle')} :{' '}
          {damage_report.reservation.vehicle_plate}{' '}
          {damage_report.reservation.vehicle_make}{' '}
          {damage_report.reservation.vehicle_modele} <br />
          <br />
          {t('damage_report:submit-content-1')}
          <br />
          {t('damage_report:submit-content-2')}
        </Typography>
      </Container>
      <AppBar className={classes.AppBarBottom} position="fixed">
        <Container color="primary" maxWidth="md">
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                onClick={submitEndDamageReport}
                className={classes.Button}
                disabled={isLoading}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('damage_report:submit-button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </div>
  );
}

export default withRouter(SubmitPage);
