import { GET_GEOLOC } from '../actions/user';

import {
  INSERT_NEW_ADDITIONAL_SIDE,
  UPDATE_STEP,
  FIND_RESERVATION,
  SUBMITED_DAMAGE_REPORT,
  DAMAGE_REPORT_TYPE,
  DAMAGE_REPORT_STEP_LEFT,
  DAMAGE_REPORT_STEP_FRONT,
  DAMAGE_REPORT_STEP_RIGHT,
  DAMAGE_REPORT_STEP_BACK,
  DAMAGE_REPORT_STEP_ODOMETER,
  DAMAGE_REPORT_STEP_ADDITIONAL,
  DAMAGE_REPORT_STEP_SUBMIT
} from '../actions/damage_report';

const initialState = {
  isAuthencticated: false,
  isGeoloc: false,
  geolocationCoordinates: [],
  currentUserEmail: null,
  reservation: {
    id: null,
    uuid: null,
    contrat_id: null,
    tenant_uuid: null,
    reservation_id: null,
    contrat_id: null,
    damage_reports: [],

    customer_email: null,
    customer_firstname: null,
    customer_lastname: null,
    driver_email: null,
    driver_firstname: null,
    driver_lastname: null,
    manager_email: null,

    location_end_code: null,
    location_end_address_line1: null,
    location_end_address_line2: null,
    location_end_address_line3: null,
    location_end_address_line4: null,
    location_end_address_locality: null,
    location_end_address_postal_code: null,

    location_start_code: null,
    location_start_address_line1: null,
    location_start_address_line2: null,
    location_start_address_line3: null,
    location_start_address_line4: null,
    location_start_address_locality: null,
    location_start_address_postal_code: null,

    vehicle_make: null,
    vehicle_modele: null,
    vehicle_plate: null,

    reservation_start_at: null,
    reservation_end_at: null,
    created_at: null,
    updated_at: null
  },
  damageReportType: null,
  isDamageReportComplete: false,
  currentStep: 'LEFT',
  // currentStep: 'ADDITIONAL',
  // currentStep: 'SUBMIT',
  LEFT: null,
  FRONT: null,
  RIGHT: null,
  BACK: null,
  ODOMETER: null,
  ADDITIONAL: [null]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GEOLOC:
      return {
        ...state,
        isGeoloc: true,
        geolocationCoordinates: action.geolocationCoordinates
      };
    case FIND_RESERVATION:
      return {
        ...state,
        currentUserEmail: action.currentUserEmail,
        isAuthenticated: true,
        reservation: action.reservation
      };
    case UPDATE_STEP:
      return {
        ...state,
        [action.step]: action.value
      };
    case DAMAGE_REPORT_TYPE:
      return {
        ...state,
        damageReportType: action.damageReportType
      };
    case DAMAGE_REPORT_STEP_LEFT:
      return {
        ...state,
        currentStep: 'LEFT'
      };
    case DAMAGE_REPORT_STEP_FRONT:
      return {
        ...state,
        currentStep: 'FRONT'
      };
    case DAMAGE_REPORT_STEP_RIGHT:
      return {
        ...state,
        currentStep: 'RIGHT'
      };
    case DAMAGE_REPORT_STEP_BACK:
      return {
        ...state,
        currentStep: 'BACK'
      };
    case DAMAGE_REPORT_STEP_ODOMETER:
      return {
        ...state,
        currentStep: 'ODOMETER'
      };
    case DAMAGE_REPORT_STEP_ADDITIONAL:
      return {
        ...state,
        currentStep: 'ADDITIONAL'
      };
    case DAMAGE_REPORT_STEP_SUBMIT:
      // localStorage.setItem('state', JSON.stringify(state));
      return {
        ...state,
        currentStep: 'SUBMIT'
      };
    case SUBMITED_DAMAGE_REPORT:
      return {
        ...state,
        LEFT: null,
        FRON: null,
        RIGHT: null,
        BACK: null,
        ODOMETER: null,
        ADDITIONAL: [null],
        currentStep: 'LEFT',
        isDamageReportComplete: true,
        currentUserEmail: null,
        reservation: {
          id: null,
          uuid: null,
          contrat_id: null,
          tenant_uuid: null,
          reservation_id: null,
          contrat_id: null,
          damage_reports: [],

          customer_email: null,
          customer_firstname: null,
          customer_lastname: null,
          driver_email: null,
          driver_firstname: null,
          driver_lastname: null,
          manager_email: null,

          location_end_code: null,
          location_end_address_line1: null,
          location_end_address_line2: null,
          location_end_address_line3: null,
          location_end_address_line4: null,
          location_end_address_locality: null,
          location_end_address_postal_code: null,

          location_start_code: null,
          location_start_address_line1: null,
          location_start_address_line2: null,
          location_start_address_line3: null,
          location_start_address_line4: null,
          location_start_address_locality: null,
          location_start_address_postal_code: null,

          vehicle_make: null,
          vehicle_modele: null,
          vehicle_plate: null,

          reservation_start_at: null,
          reservation_end_at: null,
          created_at: null,
          updated_at: null
        },
        geolocationCoordinates: []
      };
    default:
      return state;
  }
};
