import axios from 'axios';
import config from '../../config';
import httpClient from '../httpClient';
import _ from 'lodash';

const API_URL = _.trimEnd(config.api.url, '/');

export default {
  getPictureLinkUrl() {
    return httpClient(API_URL).get('/pictures/link', {});
  },
  uploadPictureS3(link, file, config = {}) {
    axios({
      method: 'PUT',
      url: link,
      data: file, // NOTE - this is the file not the FormData Object
      headers: {
        'Content-Type': 'image/jpeg'
      }
    })
      .then(res => {
        this.setState({
          uploadSuccess: 'File upload successfull',
          error: undefined
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
};
