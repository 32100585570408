import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { DAMAGE_REPORT_STEP_FRONT } from '../../store/actions/damage_report';
import left from '../../assets/images/damage_report/left@2x.png';
import Step from '../../components/damage_report/Step';

function LeftPage({ history }) {
  const { t } = useTranslation(['damage_report']);

  return (
    <div className="">
      <Step
        title={t('damage_report:left-step-title')}
        description={t('damage_report:left-step-description')}
        currentStep={'LEFT'}
        nextStep={DAMAGE_REPORT_STEP_FRONT}
        image={left}
      />
    </div>
  );
}

export default withRouter(LeftPage);
