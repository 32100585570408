import api from '../../utils/api';
import moment from 'moment-timezone';

export const INSERT_NEW_ADDITIONAL_SIDE = 'INSERT_NEW_ADDITIONAL_SIDE';
export const UPDATE_STEP = 'UPDATE_STEP';

export const DAMAGE_REPORT_TYPE = 'DAMAGE_REPORT_TYPE';
export const DAMAGE_REPORT_STEP_LEFT = 'DAMAGE_REPORT_STEP_LEFT';
export const DAMAGE_REPORT_STEP_FRONT = 'DAMAGE_REPORT_STEP_FRONT';
export const DAMAGE_REPORT_STEP_RIGHT = 'DAMAGE_REPORT_STEP_RIGHT';
export const DAMAGE_REPORT_STEP_BACK = 'DAMAGE_REPORT_STEP_BACK';
export const DAMAGE_REPORT_STEP_ODOMETER = 'DAMAGE_REPORT_STEP_ODOMETER';
export const DAMAGE_REPORT_STEP_ADDITIONAL = 'DAMAGE_REPORT_STEP_ADDITIONAL';
export const DAMAGE_REPORT_STEP_SUBMIT = 'DAMAGE_REPORT_STEP_SUBMIT';

export const SUBMITED_DAMAGE_REPORT = 'SUBMITED_DAMAGE_REPORT';
export const FIND_RESERVATION = 'FIND_RESERVATION';

export function findReservation({
  current_user,
  vehicle_plate,
  reservation_start_date,
  reservation_start_hour
}) {
  return async function(dispatch) {
    let date = moment.tz(
      reservation_start_date + ' ' + reservation_start_hour,
      'DD/MM/YYYY h:mm',
      'Europe/Paris'
    );
    let utcDate = date.utc();

    try {
      const response = await api.damage_report.findReservation(
        vehicle_plate,
        utcDate.toISOString()
      );

      if (response.reservation == null) throw 'RESERVATION_NOT_FOUND';

      // localStorage.setItem('reservation', JSON.stringify(response.reservation));

      dispatch({
        type: FIND_RESERVATION,
        currentUserEmail: current_user,
        reservation: response.reservation
      });
    } catch (error) {
      throw error;
    }
  };
}

export function uploadPicture(currentStep, file) {
  return async function(dispatch) {
    try {
      const responseLink = await api.picture.getPictureLinkUrl();
      const response = await api.picture.uploadPictureS3(
        responseLink.upload_url,
        file,
        {}
      );

      dispatch({
        type: UPDATE_STEP,
        step: currentStep,
        value: {
          type: currentStep,
          key: responseLink.key,
          comment: null
        }
      });

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function submitDamageReport(uuid, damageReport) {
  return async function(dispatch) {
    try {
      const response = await api.damage_report.submitDamageReport(
        uuid,
        damageReport
      );

      dispatch({
        type: SUBMITED_DAMAGE_REPORT
      });
    } catch (error) {
      throw error;
    }
  };
}
