import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const isAuthenticated = true;
  const isAuthenticated = useSelector(
    state => state.damage_report.isAuthenticated
  );

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
