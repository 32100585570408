import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import { dateLongFormat } from '../utils/variables';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import config from '../config';
import logo from '../assets/images/logo-retina.png';
import { findReservation } from '../store/actions/damage_report';
import { getParameterByName } from '../utils/paramsHelper';

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: '120px',
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  textCenter: {
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  text: {
    textAlign: 'center',
    maxWidth: '500px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function LoginPage({ history }) {
  const classes = useStyles();
  const { t } = useTranslation(['damage_report', 'validation']);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const current_user = getParameterByName('email');
  const tenant_uuid = getParameterByName('tenant_uuid');
  const vehicle_plate = getParameterByName('vehicle');
  const reservation_start_at = getParameterByName('reservation_start_at');
  const reservation_end_at = getParameterByName('reservation_end_at');
  const utcCutoff = moment.utc(reservation_start_at);
  const dateToFr = utcCutoff.clone().tz('Europe/Paris');

  const initialValues = {
    current_user: current_user ? current_user : '',
    tenant_uuid: tenant_uuid ? tenant_uuid : '',
    vehicle_plate: vehicle_plate ? vehicle_plate : '',
    reservation_start_date: reservation_start_at
      ? dateToFr.format('DD-MM-YYYY')
      : '',
    reservation_start_hour: reservation_start_at ? dateToFr.format('H:mm') : ''
  };

  const validationSchema = Yup.object().shape({
    current_user: Yup.string().required(t('validation:required'))
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await dispatch(findReservation(values));
        history.push(`${config.auth.rootUrl}etat-des-lieux/geoloc`);
      } catch (error) {
        enqueueSnackbar(t('validation:reservation-not-found'), {
          variant: 'error'
        });
        setSubmitting(false);
      }
    }
  });

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={logo} alt={'logo'}></img>
        <Typography color="primary" className={classes.title} variant="h4">
          {t('damage_report:login-title')}
        </Typography>
        <Typography color="primary" className={classes.text} gutterBottom>
          {t('damage_report:login-content')}
        </Typography>
        <br />
        <Typography color="primary" variant="h5" gutterBottom>
          {t('damage_report:login-subtitle')}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            id="vehicle_plate"
            name="vehicle_plate"
            variant="outlined"
            label="Plaque d'immatriculation"
            margin="normal"
            value={values.vehicle_plate}
            onChange={handleChange}
            error={touched.vehicle_plate && !!errors.vehicle_plate}
            helperText={
              touched.vehicle_plate && errors.vehicle_plate
                ? errors.vehicle_plate
                : null
            }
            required
            fullWidth
          />

          <Grid spacing={1} container>
            <Grid item xs={6}>
              <TextField
                id="reservation_start_date"
                name="reservation_start_date"
                variant="outlined"
                label="Date de début réservation"
                placeholder="01-12-2021"
                margin="normal"
                value={values.reservation_start_date}
                onChange={handleChange}
                error={
                  touched.reservation_start_date &&
                  !!errors.reservation_start_date
                }
                helperText={
                  touched.reservation_start_date &&
                  errors.reservation_start_date
                    ? errors.reservation_start_date
                    : null
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="reservation_start_hour"
                name="reservation_start_hour"
                variant="outlined"
                label="Heure de début réservation"
                placeholder="15:30"
                margin="normal"
                value={values.reservation_start_hour}
                onChange={handleChange}
                error={
                  touched.reservation_start_hour &&
                  !!errors.reservation_start_hour
                }
                helperText={
                  touched.reservation_start_hour &&
                  errors.reservation_start_hour
                    ? errors.reservation_start_hour
                    : null
                }
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            id="current_user"
            name="current_user"
            variant="outlined"
            label="Auteur"
            margin="normal"
            fullWidth
            autoFocus
            value={values.current_user}
            onChange={handleChange}
            error={touched.current_user && !!errors.current_user}
            helperText={
              touched.current_user && errors.current_user
                ? errors.current_user
                : null
            }
          />

          <Button
            size="large"
            type="submit"
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            className={classes.submit}
          >
            {t('damage_report:login-button')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default withRouter(LoginPage);
